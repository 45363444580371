import styled, { keyframes } from "styled-components";
import { Heading } from "../../globalStyles";
import { HeroHeading } from "./HeroStyles";

export const AnimationContainer = styled.div`
    width: 15rem;
    height: 15rem;
    border: 2px solid white;
    position: absolute;
    background-color: #2ac;
    top: 10px;
    left: 10px; 
    /* transform: translate(-50%, 0); */
    border: solid 20px darkblue;
    border-radius: 50%;
`;

export const LineAnimationLeft = keyframes`
    0% { transform: translateX(-100%)}
    100% { transform: translateX(0%)}
`;

export const LineAnimationRight = keyframes`
    0% { transform: translateX(100%)}
    100% { transform: translateX(0%)}
`;

export const HeaderAnimation = keyframes`
    0% {
        letter-spacing: -0.5rem;
        -webkit-transform: translateZ(-800px);
        transform: translateZ(-800px);
        -webkit-filter: blur(12px);
        filter: blur(12px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        -webkit-filter: blur(0);
        filter: blur(0);
        opacity: 1;
    }
`;

export const AnimatedHeader = styled(HeroHeading)`
    animation-name: ${HeaderAnimation};
    animation-duration: 1s;
    animation-iteration-count: 1;
`;

export const AnimationLineLeft = styled.div`
    background-color: ${({ background_color }) => ( background_color ? background_color : "")};
    height: 0.5rem;
    width: ${({ width }) => ( width ? width : '20rem')};
    position: absolute;
    top: ${({ top }) => ( top ? top : '0%')};
    left: ${({ left }) => ( left ? left : '0%')};
    animation-name: ${LineAnimationLeft};
    animation-duration: 1s;
    animation-iteration-count: 1;
    z-index: 5;
`;

export const AnimationLineRight = styled.div`
    background-color: ${({ background_color }) => ( background_color ? background_color : "")};
    height: 0.5rem;
    width: ${({ width }) => ( width ? width : '20rem')};
    position: absolute;
    bottom: ${({ bottom }) => ( bottom ? bottom : '0%')};
    right: ${({ right }) => ( right ? right : '0%')};
    animation-name: ${LineAnimationRight};
    animation-duration: 1s;
    animation-iteration-count: 1;
    z-index: 5;
`;

export const AnimationHead = styled.div`
    background-color: pink;
    height: 10rem;
    width: 8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50% 50% 25% 25%;
    transform-origin: center;

    &:before{
        left: -0.75rem;
        content: "";
        position: absolute;
        top: 50%;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50% 50%;
        background-color: pink;
    }
    &:after{
        right: -0.75rem;
        content: "";
        position: absolute;
        top: 50%;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50% 50%;
        background-color: pink;
    }
`;

export const AnimationEye = styled.div`
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    position: absolute;
    background-color: black;
    top: 50%;
    left: ${({ left }) => ( left ? left : "")};
    right: ${({ right }) => ( right ? right : "")};
`;

export const AnimationNose = styled.div`
    width: 2rem;
    height: 3rem;
    background-color: salmon;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 0);
    border-radius: 50% 50% 40% 40%;

    &:before {
        content: "";
        width: 1rem;
        height: 1rem;
        
        position: absolute;
        background-color: salmon;
        /* border: 1px solid black; */
        border-radius: 50%;
        left: -15%;
        bottom: 10%;
        z-index: 0;
    }

    &:after {
        content: "";
        width: 1rem;
        height: 1rem;
        
        position: absolute;
        background-color: salmon;
        /* border: 1px solid black; */
        border-radius: 50%;
        right: -15%;
        bottom: 10%;
        z-index: 0;
    }
`;

export const AnimationBlush = styled.div`
    position: absolute;
    height: 1.3rem;
    width: 1.3rem;
    border-radius: 50%;
    background-color: #f66;
    top: 65%;
    left: ${({ left }) => ( left ? left : "")};
    right: ${({ right }) => ( right ? right : "")};
    /* transform: translateX(-50%); */
    z-index: 2;
`;

export const AnimationMouth = styled.div`
    position: absolute;
    height: 1rem;
    width: 3rem;
    background-color: black;
    border-radius: 0 0 50% 50%;
    top: 80%;
    left: 50%;
    transform: translateX(-50%);
    display: flex; 
    flex-direction: row;
    z-index: -1;
`;

export const AnimationTooth = styled.div`
    position: relative;
    background-color: white;
    width: 16.67%;
    height: ${({ height }) => (height ? height : "")};
    top: 0;
    z-index: 0;
    border-radius: 0 0 50% 50%;
`;

export const AnimationHelmet = styled.div`
    position: absolute;
    background-color: #e55;
    width: 8.5rem;
    height: 4.5rem;
    top: 2rem;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
    border-radius: 12.75rem 12.75rem 0 0;

    &:before {
        background-color: #e66;
        content: "";
        width: 9rem;
        height: 0.75rem;
        position: absolute;
        bottom: 0;
        left: -0.25rem;
        border-radius: 50% 50% 0 0;
    }

    &:after {
        background-color: pink;
        content: "";
        width: 8rem;
        height: 0.75rem;
        position: absolute;
        bottom: -0.5rem;
        left: 0.25rem;
        border-radius: 50% 50% 0 0;
    }
`;
