export const formData = [
    {
        type: "text",
        id: "name",
        name: "name",
        placeholder: "Nimi",
    },
    {
        type: "text",
        id: "company",
        name: "company",
        placeholder: "Yritys",
    },
    {
        type: "text",
        id: "phone",
        name: "phone",
        placeholder: "Puhelin",
    },
    {
        type: "text",
        id: "email",
        name: "email",
        placeholder: "Sähköposti",
    },
    {
        type: "text",
        id: "message",
        name: "message",
        placeholder: "Viesti",
    }
]

export const formText = [
    {
        title: "Yhteydenotto!",
        text: "Ota reippaasti yhteyttä ja pyydä tarjousta projektillesi. Olemme yhteydessä mahdollisimman pian!"
    }
]