import GlobalStyles                                 from "./globalStyles";
// import React, { useState, useEffect }               from "react";
import { BrowserRouter as Router, Routes, Route }   from 'react-router-dom';
import Home                                         from "./pages/Home";
import Palvelut                                     from "./pages/Palvelut";
import Referenssit                                  from "./pages/Referenssit";
import Yhteystiedot                                 from "./pages/Yhteystiedot";
import { ThemeProvider }                            from "./Themes";

function App() {
    return (
        <ThemeProvider>
            <Router>
                <GlobalStyles />
                <Routes>
                    <Route path='/'             element={ <Home/> }/>
                    <Route path='/palvelut'     element={ <Palvelut/> }/>
                    <Route path='/referenssit'  element={ <Referenssit/> }/>
                    <Route path='/yhteystiedot' element={ <Yhteystiedot/> }/>
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
