import { createContext, useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "./globalStyles";

export const themes = {
    navyBlue: {
        base: '#324ab2 ', //navy blue
        heroBase: 'linear-gradient(30deg, #324ab2, #8888BB)',
        analogous1: '#708090', // slate gray
        analogous2: '#4682B4', //steel blue
        accent: '#FF5733', //safety orange
        neutral: '#36454f', //charcoal gray,
        text: 'white',
    },
    brickRed: {
        additional: {},
        base: '#36454f', //charcoal gray
        heroBase: 'linear-gradient(to right, #333344, #888B8D)', //charcoal gray
        analogous1: '#708090', //slate gray
        analogous2: '#888B8D', //steel gray
        accent: '#cb4154', //brick red
        neutral: '#333344', //light gray, D9D9D6
        text: '#ccc',
    },
    disco: {
        base: '#B3DFFC', //light blue
        heroBase: 'linear-gradient(to right, blue, red)',//#B3DFFC, #e7f5fe)',
        analogous1: '#FFD166', //slate gray
        analogous2: '#F5F5F5', //steel gray
        accent: 'transparent; background: linear-gradient(to right, #8080ff, #ff8080);-webkit-background-clip: text; background-clip: text;',//'#FFA07A', //brick red //FFD166
        neutral: '#D9D9D6', //light gray //D9D9D6
        text: 'transparent', 
    },
    /*
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
                */
    industrialBlueYellow: {
        base: '#6e6e91', //light blue
        heroBase: 'linear-gradient(to right, #333344, #6e6e91)',
        analogous1: '#D9D9D6', //slate gray
        analogous2: '#6e6e91', //steel gray
        accent: '#ff8000', //brick red //FFD166 888B8D 4682B4 B7410E
        neutral: '#333344', //light gray //D9D9D6
        text: '#fff', 
    },
    // additional: {
    //     background: 'linear-gradient(to right, blue, red)',
    //     webkit_background_clip: 'text',
    //     background_clip: 'text',
    //     color: 'transparent',
    // },
};

export const ThemeButton = styled(Button)`
    position: absolute;
    top: 20px;
    right: 20px;
    /* margin-right: 60px;
    margin-top: 20px; */
    @media screen and (max-width: 1280px ){
        top:120px;
    }
    @media screen and (max-width: 768px){
        top: 240px;
    }
`;

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
    const [ currentTheme, setCurrentTheme ] = useState(themes.industrialBlueYellow);

    const themePicker = () => {
        if( currentTheme === themes.industrialBlueYellow ){
            setCurrentTheme( themes.brickRed );
        }
        if( currentTheme === themes.brickRed ){
            setCurrentTheme( themes.navyBlue );
        }
        if( currentTheme === themes.navyBlue ){
            setCurrentTheme( themes.disco )
        }
        if( currentTheme === themes.disco){
            setCurrentTheme( themes.industrialBlueYellow );
        }
    }

    const toggleTheme = () => {
        themePicker();
        // setCurrentTheme( currentTheme === themes.brickRed ? themes.navyBlue : themes.navyBlue ? themes.blueYellow : themes.industrialBlueYellow); 
        // setCurrentTheme( currentTheme === themes.brickRed ? themes.blueYellow : themes.blueYellow) 
        // setCurrentTheme( currentTheme === themes.blueYellow ? themes.navyBlue : themes.industrialBlueYellow) 
        
                                        
                                        // ? themes.blueYellow 
                                        // : themes.blueYellow ? themes.navyBlue 
                                        // : themes.navyBlue ? themes.industrialBlueYellow : themes.brickRed  );
    };

    return (
        <ThemeContext.Provider value={{ theme: currentTheme, toggleTheme }}>
            { children }
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);