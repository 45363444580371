import React, { useEffect, useRef, useState } from 'react';
import { Column, Section, Item, Row, Heading, Text, Image, Button, Container, Link } from '../../globalStyles';
import { fetchData, arrayToString } from '../../functions/Functions';
import { ContentsGrid, ContentsRow, ContentsItem, ContentsImage } from './ContentsStyles';
import { addHtml } from '../../functions/Functions';

const Contents = ({ page, text_area_width, theme }) => {
    const [fileData, setFileData] = useState([]);
    const [gridInfo, setGridInfo] = useState("");
    const [gridTemplateArray, setGridTemplateArray] = useState([]);

    useEffect(() => {
        (async () => {
            const data = await fetchData({ page });
            setFileData(data.sections);
            setGridInfo(data.grid);
        })();

    }, [page]);



    useEffect(() => {
        gridTemplateCounter(gridInfo.gridColumns);
        // console.log( arrayToString( gridTemplateArray ));
    }, [gridInfo])

    const gridTemplateCounter = (gridColumns) => {
        for (let i = 0; i < gridColumns; i++) {
            setGridTemplateArray(gridTemplateArray => [...gridTemplateArray, 'auto ']);
        }
    }

    const textAlignChecker = (index) => {
        let string = ""
        console.log("INDEX: " + index)
        if (index === 1) {
            string = 'start'
        } else {
            string = 'center'
        }
        console.log("STRING: " + string)
        return string
    }

    const clickHandler = () => {
        console.log("HÄHÄHÄÄÄÄ")

        /* if(link==""){
            e.preventDefault();
            console.log(link);
        } else {
            return;
        } */
    }

    return (
        <Column >
            <Section background='transparent'>
                <Column padding={'0'} margin={'0.5rem 0'}>
                    {page != 'yhteystiedot' ? fileData.map((el, index) => (
                        <ContentsRow
                            key={index}
                            width={text_area_width}
                            justify_content={'start'}
                            align_items={'top'}
                            flex_direction={el.reverse ? 'row-reverse' : 'row'}
                            margin={'0.5rem 0'}
                            padding={'5vh 2vw'}
                            background={theme.neutral}
                            box_shadow={'0 0 5px 0px ' + theme.accent}
                            id={'section' + el.index}
                        >
                            {el.image ?
                                <>
                                    <ContentsItem>
                                        <ContentsImage src={el.image}
                                            width='30vw'
                                            border={'solid 0.5vh ' + theme.accent}
                                            border_radius={'20px'}
                                            background={theme.analogous2} />
                                    </ContentsItem>
                                    <ContentsItem >
                                        <Heading text_align={'start'}
                                            color={theme.accent}
                                        >
                                            {el.title}
                                        </Heading>
                                        {el.text.map((elem, i) => (
                                            <Text key={i}
                                                text_align={'start'}
                                                color={theme.text}
                                                link_color={theme.accent}
                                            >
                                                {addHtml(elem)}
                                            </Text>
                                        ))}

                                    </ContentsItem>
                                </>
                                :
                                <ContentsItem >
                                    <Heading text_align={'center'}
                                        color={theme.text}
                                    >
                                        {el.title}
                                    </Heading>
                                    {el.text.map((elem, i) => (
                                        <Text key={i}
                                            text_align={textAlignChecker(el.index)}
                                            color={theme.text}
                                            link_color={theme.accent}
                                        >
                                            {addHtml(elem)}
                                        </Text>
                                    ))}
                                </ContentsItem>
                            }
                        </ContentsRow>
                    )) : ""}
                </Column>
            </Section>
        </Column>

    )
}

export default Contents
