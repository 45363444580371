import { styled } from "styled-components";
import { Row } from "../../globalStyles";

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    width: 100%;
    height: auto;

    @media screen and (max-width:768px){
        margin: 10px;
    }
`;

export const FormInput = styled.input`
    width: 100%;
    margin: 10px 0;
    padding: 5px;
    background: #eee;
    opacity: 0.8;
    outline: none;
    border: 3px solid #ccc;

    &:focus {
        opacity: 1;
        border: ${({ border }) => (border ? border : 'none')}; 
    }

    @media screen and (max-width:768px){
        margin: 5% 0;
        padding: 5% 5%;
    }
`;

export const FormButton = styled.button`
    
`;

export const ContactFormRow = styled(Row)`

    width:65vw;

    @media screen and (max-width: 768px) {
        width: 90vw;
        flex-direction: column;
        padding: 0 0;
    }
`;
