import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { CarouselFocuser, CarouselImage, SliderContainer } from './CarouselStyles';
import { fetchImageData } from '../../functions/Functions';
import { Image, Column, Heading } from '../../globalStyles';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Carousel = ({ theme, page, settings, carouselProps, box_hover, handleImageClick, setImageIndex, setImagesLarge }) => {
    const [images, setImages] = useState([""]);
    const slider = React.useRef(null);

    useEffect(() => {
        (async () => {
            const data = await fetchImageData(page);
            setImages(data);
            setImagesLarge(data);
        })();
    }, []);

    const handleAfterChange = (index) => {
        setImageIndex(index);
    }

    return (
        <Column>
            <Heading color={theme.text}>Kuvagalleria</Heading>
            <SliderContainer background={carouselProps.background}
                width={carouselProps.width}
                box_shadow={carouselProps.box_shadow}
                position={carouselProps.position}
                min_width={carouselProps.min_width}
                min_height={carouselProps.min_height}
                height={carouselProps.height}
                box_hover={box_hover}

            >
                <Slider ref={slider} {...settings} afterChange={handleAfterChange}>
                    {images.map((img, index) => (
                        <CarouselFocuser onClick={handleImageClick}
                            key={index}
                            height={carouselProps.height}>
                            <CarouselImage src={img}

                                height={carouselProps.height}
                                border_radius={carouselProps.border_radius}
                                filter={carouselProps.filter}
                                min_height={carouselProps.minHeight}
                                object_fit={carouselProps.object_fit}
                            />
                        </CarouselFocuser>
                    ))}
                </Slider>
            </SliderContainer>
        </Column>

    )
}

export default Carousel
