import styled from "styled-components";
import { Item, Container, Image, List, ListItem, Text } from '../../globalStyles'



export const NavbarItem = styled(Item)`
    font-weight: 600;
    text-shadow: 0.2rem 0.2rem black;
    padding: 1rem 2vw;
    /* font-size: clamp('1vw, 1rem, 2vw'); */
    @media screen and ( max-width: 960px ) {
        padding: 1rem 1vw;
    }

    @media screen and ( max-width: 768px) {
        display: none;
    }
    
`;

export const NavbarText = styled(Text)`
    word-wrap: normal;
    color: white;
    font-size: 1rem;
    text-shadow: ${({ text_shadow }) => ( text_shadow ? text_shadow : '')};
    text-decoration: ${({ text_decoration }) => ( text_decoration ? text_decoration : '')};
    border-bottom: ${({ border_bottom }) => ( border_bottom ? border_bottom : '')};

    @media screen and (max-width: 960px) {
        font-size: 1rem;
    }

    &:hover {
        color: ${({ color }) => ( color ? color : '')};
    }
`;

export const NavbarContainer = styled(Container)`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: transparent;
    z-index: 2;
    width: 100%;
`;

export const NavbarImage = styled(Image)`
    margin: ${({ margin }) => ( margin ? margin : ' 0 20vw 0 0')};
    @media screen and (max-width: 960px) {
        margin: 0 15vw 0 0;
    }

    &:hover {
        transform: scale(1.05);
    }
`;

export const NavMenu = styled(List)`
    display: flex;
    list-style: none;
    width: 100%;

    @media screen and (max-width: 768px) {
        width: 100%;
        height: 100vh;
        flex-direction: column;
        position: fixed;
        align-items: flex-end;
        padding-top: 30%;
        top: 0;
        left: 0;
        opacity: ${({ show }) => ( show ? 1 : 0)};
        visibility: ${({ show }) => ( show ? 'visible' : 'hidden')};
        transform: translateY(${({ show }) => ( show ? '0' : '-10px ')});
        transition: opacity 0.5 ease;
        background: transparent;
    }

    *:first-child {
        margin-top: 20px;
    }
`;

export const MobileIcon = styled.div`
    display: none;
    max-width: 160px;

    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }
`;

export const NavmenuItem = styled(ListItem)`
    display: ${({ show }) => ( show ? 'inline' : 'none')};
    font-weight: 600;
    text-shadow: 0.2rem 0.2rem black;
    /* padding: 0.2rem 2vw; */
`;