import styled, { createGlobalStyle } from "styled-components";


const GlobalStyles = createGlobalStyle`
    

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        font-family: 'Roboto', sans-serif;
    }

    a {
        text-decoration: none;
        cursor: pointer;
    }

    ul {
        padding-left: 2rem;

        ::marker{
            color: ${({ color }) => (color ? color : '')};
        }
    }
`;

export const Container = styled.div`
    background: ${({ background }) => (background ? background : "")};
    /* background: linear-gradient('red', 'black'); */
    max-width: ${({ max_width }) => (max_width ? max_width : '100vw')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '1.5rem')};
    z-index: ${({ z_index }) => (z_index ? z_index : '-2')};
    border: ${({ border }) => (border ? border : '')};
    border-left: ${({ border_left }) => (border_left ? border_left : '')};
    border-right: ${({ border_right }) => (border_right ? border_right : '')};
    border-top: ${({ border_top }) => (border_top ? border_top : '')};
    border-bottom: ${({ border_bottom }) => (border_bottom ? border_bottom : '')};
    box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : '')};
`;

export const Section = styled.section`
    position: ${({ position }) => (position ? position : 'static')};
    bottom: ${({ bottom }) => (bottom ? bottom : '0')};
    background: ${({ background }) => (background ? background : "transparent")};
    max-width: ${({ max_width }) => (max_width ? max_width : '65vw')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    z-index: ${({ z_index }) => (z_index ? z_index : '0')};

    @media screen and (max-width:768px){
        max-width: 100vw; 
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: ${({ justify_content }) => (justify_content ? justify_content : 'start')};
    align-items: top;
    width: ${({ width }) => (width ? width : '100%')};
`;

export const Row = styled.div`
    background: ${({ background }) => (background ? background : "transparent")};
    display: flex;
    flex-direction: ${({ flex_direction }) => (flex_direction ? flex_direction : 'row')};
    justify-content: ${({ justify_content }) => (justify_content ? justify_content : 'center')};
    align-items: ${({ align_items }) => (align_items ? align_items : 'center')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '3rem 3rem')};
    z-index: ${({ z_index }) => (z_index ? z_index : '0')};
    border: ${({ border }) => (border ? border : 'none')};
    box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : '')};

   // @media screen and (max-width: 768px) {
   //     width:100vw; 
   // } 
`;

export const Column = styled.div`
    background: ${({ background }) => (background ? background : "transparent")};
    position: ${({ position }) => (position ? position : 'static')};
    top: ${({ top }) => (top ? top : '0')};
    left: ${({ left }) => (left ? left : '0')};
    display: ${({ display }) => (display ? display : 'flex')};
    flex-direction: column;
    justify-content: ${({ justify_content }) => (justify_content ? justify_content : 'center')};
    align-items: ${({ align_items }) => (align_items ? align_items : 'center')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    min-width: ${({ min_width }) => (min_width ? min_width : '')};
    min-height: ${({ min_height }) => (min_height ? min_height : '')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    z-index: ${({ z_index }) => (z_index ? z_index : '0')};
    box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : '')};
    border: ${({ border }) => (border ? border : 'none')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '')};
    box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : '')};
`;

export const Item = styled.div`
    background: ${({ background }) => (background ? background : "transparent")};
    //text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
    max-width: ${({ max_width }) => (max_width ? max_width : '100%')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '0 2%')};
    z-index: ${({ z_index }) => (z_index ? z_index : '0')};
    border: ${({ border }) => (border ? border : '')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '')};
    line-height: ${({ line_height }) => (line_height ? line_height : '')};
`;

export const Image = styled.img`
    display: ${({ display }) => (display ? display : 'block')};
    position: ${({ position }) => (position ? position : 'inline')};
    object-fit: ${({ object_fit }) => (object_fit ? object_fit : 'contain')};
    background: ${({ background }) => (background ? background : 'transparent')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '')};
    max-width: ${({ max_width }) => (max_width ? max_width : '')};
    max-height: ${({ max_height }) => (max_height ? max_height : '100%')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    border: ${({ border }) => (border ? border : '')};
    box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : '')};
    z-index: ${({ z_index }) => (z_index ? z_index : '')};
`;

export const Heading = styled.h3`
    width: ${({ width }) => (width ? width : '100%')};
    color: ${({ color }) => (color ? color : color)};
    font-size: ${({ font_size }) => (font_size ? font_size : 'clamp(1.5vw, 2.5rem, 3vw)')};
    text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
    margin-bottom: ${({ margin_bottom }) => (margin_bottom ? margin_bottom : "2.5rem")};

    @media screen and (max-width:768px) {
        font-size: 3rem;
    }

    @media screen and (max-width:480px){
        font-size: 2rem;
    }
`;

export const Text = styled.span`
    font-size: ${({ font_size }) => (font_size ? font_size : 'clamp(1vw, 1.4rem, 2vw)')};
    text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    padding: ${({ padding }) => (padding ? padding : '0')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    background: ${({ background }) => (background ? background : 'transparent')};
    color: ${({ color }) => (color ? color : '')};
    line-height: ${({ line_height }) => (line_height ? line_height : 'clamp(1.5vw, 2.2rem, 3vw)')};
    border: ${({ border }) => (border ? border : '')};
    border-radius: ${({ border_radius }) => (border_radius ? border_radius : '')};
    
    line-break: loose;
    
    a {
        color: ${({ link_color }) => (link_color ? link_color : 'red')};
    }

    li {
        list-style: none;

        &::before {
        display:inline-block;
        background-color: transparent;
        content: "";
        border-bottom: 0.3rem solid ${({ link_color }) => (link_color ? link_color : 'red')};
        border-left: 0.3rem solid ${({ link_color }) => (link_color ? link_color : 'red')};
        width:0.5rem;
        height:0.5rem;
        transform: rotate(225deg);
        margin-right: 1vh;
    } }

    @media screen and (max-width:768px){
        word-wrap: anywhere;
       font-size: 1.4rem;
       line-height: 2.2rem;
    }
`;

export const List = styled.ul`
    display: flex;
    list-style: none;
    width: 100%;
`;

export const ListItem = styled.li`
    text-decoration: none;
    list-style: none;
    margin-bottom: 5px;
    font-size: ${({ font_size }) => (font_size ? font_size : '1rem')};
    text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
    color: ${({ color }) => (color ? color : 'black')};
`;

export const Button = styled.button`
    color: ${({ color }) => (color ? color : 'black')};
    font-weight: 600;
    padding: ${({ padding }) => (padding ? padding : "10px 20px")};
    width: ${({ width }) => (width ? width : "")};
    height: ${({ height }) => (height ? height : 'auto')};
    margin: ${({ margin }) => (margin ? margin : '0')};
    text-align: ${({ text_align }) => (text_align ? text_align : 'center')};
    background: ${({ background }) => (background ? background : '#eee')};
    opacity: 0.8;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 3px 1.5px #aaaaaa;
    cursor: pointer;
    z-index: 5;

    &:hover {
        opacity: ${({ hover_opacity }) => (hover_opacity ? hover_opacity : '1')};
        transition: all 0.1s ease;
        box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : ' 0 0 5px 2.5px #eeeeee')};
    } 
`;

export const Link = styled.a`
    color: ${({ color }) => (color ? color : 'red')};
    text-decoration: none;
    cursor: ${({ cursor }) => (cursor ? cursor : 'pointer')};

    &:hover {
        transform: ${({ transform }) => (transform ? transform : 'none')};
    }
`;

export default GlobalStyles;
