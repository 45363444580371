import { ContentsRow } from "../Contents/ContentsStyles"
import { Column, Row, Text, Heading, Button } from "../../globalStyles.js"
import { CardAddressText, CardColumn, CardContainer, CardHeading, CardRow } from "./CardStyles"
import { useState } from "react"
import { useEffect } from "react"
import { addHtml } from "../../functions/Functions"

const Card = ({ title, text, addressText, theme }) => {
    return (
        <CardColumn width={'100%'}>
            <CardRow
                margin={'0'}
                padding={'0'}
                justify_content={'space-around'}
            >
                <CardHeading
                    color={theme.text}
                    text_align={'start'}
                    font_size={'1.6rem'}
                    margin_bottom={'0rem'}
                >
                    {title}
                </CardHeading>
                <CardAddressText
                    color={theme.text}
                >
                    {addressText}
                </CardAddressText>
            </CardRow>
            <Column>
                {text.map((txt, i) => (
                    <Text
                        key={i}
                        color={theme.text}
                        text_align={'start'}
                    >
                        {txt}
                    </Text>
                ))}
            </Column>
        </CardColumn>
    )
}

const CardList = ({ theme }) => {
    const [cards, setCards] = useState([])
    const [showAll, setShowAll] = useState(false)

    useEffect(() => {
        fetch('/data/referenssit.json')
            .then((response) => response.json())
            .then((data) => setCards(data.sections))
            .catch((error) => console.error('Error fetching data: ', error))
    }, [])

    // Function to toggle the visibility of the full card list
    const handleShowMore = () => setShowAll(!showAll)

    // Decide the cards to display: Either a few or all
    const cardsToDisplay = showAll ? cards : cards.slice(0, 3)

    return (
        <>
            <CardContainer
                margin={'0.5rem 0 1.5rem 0'}
                padding={'5vh 2vw'}
                background={theme.neutral}
                box_shadow={'0 0 5px 0px ' + theme.accent}
            >
                <Heading color={theme.text}>Referenssilista</Heading>
                {cardsToDisplay.map((card) => (
                    <ContentsRow
                        key={card.index}
                        justify_content={'start'}
                        align_items={'top'}
                        flex_direction={card.reverse ? 'row-reverse' : 'row'}

                        id={'section' + card.index}
                    >
                        <Card title={card.title} text={card.text} addressText={card.addressText} theme={theme} />
                    </ContentsRow>
                ))}
                {!showAll && cards.length > 3 && (
                    <Button onClick={handleShowMore}>
                        Näytä lisää
                    </Button>
                )}

                {showAll && (
                    <Button onClick={handleShowMore}>
                        Näytä vähemmän
                    </Button>
                )}

            </CardContainer>
        </>
    )
}

export default CardList
