import styled from "styled-components";
import { Container, Row, Item, Image } from "../../globalStyles";
import { useTheme } from '../../Themes'

export const ContentsGrid = styled(Container)`
    background: ${({ background }) => (background ? background : 'transparent')};
    padding: ${({ padding }) => (padding ? padding : '5vw')};
    width: ${({ width }) => (width ? width : '65vw')};
    display: grid;
    grid-template-columns: ${({ grid_columns }) => (grid_columns ? grid_columns : 'auto auto auto')};
    grid-gap: ${({ grid_gap }) => (grid_gap ? grid_gap : '1.5rem')};
    justify-items: ${({ justify_items }) => (justify_items ? justify_items : 'normal')};
    align-items: center;
    z-index:1;
    box-shadow: ${({ box_shadow }) => (box_shadow ? box_shadow : '')};

    @media screen and (max-width:768px){
        grid-template-columns: repeat(1, 1fr);
        
        width: 100vw;   
        padding: 1.5rem;
        
    }
`;

export const ContentsRow = styled(Row)`
    padding: 5vh 2vw;

    @media screen and (max-width: 768px) {
        width: 100vw;
        flex-direction: column-reverse;
        word-wrap: break-word;
        justify-content: center;
        align-items: center;
    }
`;

export const ContentsImage = styled(Image)`
    object-fit: contain;
    @media screen and (max-width: 768px){
        margin-top: ${({ mt }) => (mt ? mt : '3rem')};
        width: 100%;
    }
`;

export const ContentsItem = styled(Item)`
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px){
        justify-items: center;
        align-items: center;
    }
`;
