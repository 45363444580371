import styled from 'styled-components';
import { Image } from '../../globalStyles';

export const SliderContainer = styled.div`
    position: ${({ position }) => ( position ? position : 'static')};
    left: 0;
    top: 0;
    background: ${({ background }) => ( background ? background : 'transparent')};
    margin: ${({ margin }) => ( margin ? margin : '0 0')};
    min-width: ${({ min_width }) => ( min_width ? min_width : '0')};
    width: ${({ width }) => ( width ? width : '50vw' )};
    height: ${({ height }) => ( height ? height : 'auto' )};
    vertical-align: center;
    z-index: 0;
    border-radius: ${({ border_radius }) => ( border_radius ? border_radius : '5vh')};
    box-shadow: ${({ box_shadow }) => ( box_shadow ? box_shadow : '5px 5px 5px 5px #666' )};
    min-height: ${({ min_height }) => (  min_height ? min_height : 'auto' )};

    :hover {
        border-radius: ${({ border_radius }) => ( border_radius ? border_radius : '5vh')};
        box-shadow: ${({ box_hover }) => ( box_hover ? box_hover : '0 0 10px 10px #eee')};
    }

    /* @media screen and (max-width: 1280px) {
        width: ${({ width }) => ( width ? width : '80vw' )};
    } */

    @media screen and (max-width: 768px) {
        width: ${({ width }) => ( width ? width : '90vw' )};
    }
`;

export const CarouselFocuser = styled.span`
    height: ${({ height }) => ( height ? height : 'auto')};
    text-decoration: none;

    &:hover{
        cursor: pointer;
    }
`;

export const CarouselImage = styled(Image)`
    object-fit: ${({ object_fit }) => ( object_fit ? object_fit : 'cover')};
    border-radius: ${({ border_radius }) => ( border_radius ? border_radius : '5vh')};
    height: ${({ height }) => ( height ? height : 'auto')};
    width: 100%;
    min-height: ${({ min_height }) => (  min_height ? min_height : 'auto' )};
    filter: ${({ filter }) => ( filter ? filter : 'none')};
`;