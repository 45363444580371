export const fetchData = async (input) => {
    if (input.page) {
        try {
            console.log("INPUT.PAGE: " + input.page)
            const response = await fetch('/data/' + input.page + '.json');
            const data = await response.json();

            return data;

        } catch (error) {
            console.error('Error fetching data: ' + error);
        }
    }
}

export const fetchImageData = async (input) => {
    if (input) {
        console.log('/assets/' + input + 'SliderImages.json');
        try {
            const response = await fetch('/data/' + input + 'SliderImages.json');
            const data = await response.json();
            console.log("DATA: " + data.images[0])

            return data.images;

        } catch (error) {
            console.error('Error fetching data: ' + error);
        }
    }
}

export const addHtml = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }} />
}

export const scrollToSection = (ref) => {
    window.scrollTo({
        top: ref.offsetTop,
        behavior: "smooth"
    });
}

export const arrayToString = (array, length) => {
    let str = ''
    for (let i = 0; i < length; i++) {
        str += array[i];
    }
    return str;
}
