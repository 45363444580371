import React, { useState } from 'react';
import { Text, Item, Button, Row } from '../../globalStyles';
import {
    NavbarContainer,
    NavbarItem,
    NavbarText,
    NavbarImage,
    MobileIcon,
    NavMenu,
    NavmenuItem
} from './NavbarStyles';
import { Link } from 'react-router-dom';
import { IconContext } from 'react-icons';
import { FaTimes } from 'react-icons/fa';
import { CgMenuRight } from 'react-icons/cg';

const Navbar = ({ theme }) => {
    const [show, setShow] = useState(false);

    const NavbarData = [
        { "item": "Etusivu", "link": "/" },
        { "item": "Referenssit", "link": "/referenssit" },
        { "item": "Yhteystiedot", "link": "/yhteystiedot" },
    ]

    const handleMenuClick = () => {
        setShow(!show);
    }

    return (
        <IconContext.Provider value={{ color: '#fff' }}>
            <NavbarContainer>
                <Row padding={'0 25%'} justify_content={'space-between'} z_index={'10'}>
                    <Link to={'/'}>
                        <NavbarImage src='/assets/images/logo.png'
                            width={'clamp(100px, 10vw, 200px)'}
                        />
                    </Link>

                    <MobileIcon onClick={handleMenuClick}>
                        {show ? <FaTimes /> : <CgMenuRight />}
                        <NavMenu show={show}>
                            {show ? NavbarData.map((el, index) => {
                                return (
                                    <Link to={el.link} key={index}>
                                        <NavmenuItem show={show} color={'white'}>
                                            <NavbarText color={theme.accent}
                                                line_height={'0rem'}
                                                text_shadow={'1px 1px #222'}>
                                                {el.item}
                                            </NavbarText>
                                        </NavmenuItem>
                                    </Link>
                                )
                            }) : ''}
                        </NavMenu>
                    </MobileIcon>
                    {
                        NavbarData.map((el, index) => (
                            <Link key={index} to={el.link}>
                                <NavbarItem show={'none'}>
                                    <NavbarText color={theme.accent}
                                        border_bottom={'solid 2px ' + theme.accent}
                                        line_height={'1.2rem'}
                                        text_shadow={'1px 1px #222'}>
                                        {el.item}
                                    </NavbarText>
                                </NavbarItem>
                            </Link>
                        ))
                    }
                </Row>
            </NavbarContainer>
        </IconContext.Provider>
    )
}

export default Navbar
