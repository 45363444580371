import React, { useEffect, useState } from 'react';
import { Column, Row } from '../../globalStyles';
import { HeroContainer, TempHeroImage } from './HeroStyles';
import { AnimationLineLeft, AnimationLineRight, AnimatedHeader } from './HeroAnimation';
import Carousel from '../Carousel/Carousel'

const Hero = ({ theme, frontPage }) => {
    const [position, setPosition] = useState(false);
    const [textDisplayed, setTextDisplayed] = useState(false);
    const [itemsDisplayed, setItemsDisplayed] = useState(false);
    const [imageSrc, setImageSrc] = useState(0);
    const [imagesLarge, setImagesLarge] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);


    useEffect(() => {
        setPosition(frontPage ? 'static' : 'fixed');
        setTextDisplayed(frontPage ? 'flex' : 'none');
        setItemsDisplayed(frontPage ? 'inline' : 'none');
    }, [])

    const settings = {
        dots: false,
        infinite: true,
        // slidesToShow: 1,
        // slidesPerRow: 1,
        // slidesToScroll: 1,
        lazyLoad: true,
        fade: true,
        arrows: false,
        autoplay: true,
        delay: 1000,
    };

    const carouselProps = {
        position: 'fixed',
        border_radius: '0vh',
        min_height: '100vh',
        height: '100vh',
        width: '100%',
        box_shadow: '0',
        background: 'transparent',
        min_width: '100vw',
        filter: '',
    }

    return (
        <HeroContainer margin={'0 0 0 0'}
            height={'100vh'}
            width={'100%'}
            position={position}>
            <Carousel theme={theme}
                page={'referenssit'}
                settings={settings}
                carouselProps={carouselProps}
                setImageSrc={setImageSrc}
                setImagesLarge={setImagesLarge}
                setImageIndex={setImageIndex} />

            <TempHeroImage background={theme.heroBase}
                opacity={'0.8'}
                height={'100vh'}
                position={'fixed'} />
            <TempHeroImage height={'100vh'} z_index={'2'} display={itemsDisplayed}>
                <AnimationLineLeft background_color={theme.accent} top={'0%'} width={'25vw'} />
                <AnimationLineLeft background_color={theme.accent} top={'2.5%'} width={'20vw'} />
                <AnimationLineLeft background_color={theme.accent} top={'5%'} width={'15vw'} />
                <AnimationLineLeft background_color={theme.accent} top={'7.5%'} width={'10vw'} />
                <AnimationLineLeft background_color={theme.accent} top={'10%'} width={'5vw'} />
            </TempHeroImage>
            <TempHeroImage height={'100vh'} z_index={'2'} display={itemsDisplayed}>
                <AnimationLineRight background_color={theme.accent} bottom={'10%'} width={'5vw'} />
                <AnimationLineRight background_color={theme.accent} bottom={'7.5%'} width={'10vw'} />
                <AnimationLineRight background_color={theme.accent} bottom={'5%'} width={'15vw'} />
                <AnimationLineRight background_color={theme.accent} bottom={'2.5%'} width={'20vw'} />
                <AnimationLineRight background_color={theme.accent} bottom={'0'} width={'25vw'} />
            </TempHeroImage>
            <Row height={'100%'}>
                <Column width={'100vw'}
                    padding={'0 0 0 0'}
                    z_index={'2'}
                    display={textDisplayed}
                    justify_contents={'center'}>
                    <AnimatedHeader color={theme.text}
                        text_align={'center'}>
                        RKL ProBuilders
                    </AnimatedHeader>
                    <AnimatedHeader text_align={'center'}
                        font_size={'clamp(2vw, 3rem, 6vw)'}>
                        Ammattitaitoista rakentamista Uusimaa & Häme
                    </AnimatedHeader>
                </Column>
            </Row>
        </HeroContainer>
    )
}

export default Hero;

{/* <AnimationContainer>
                    <AnimationHead>
                        <AnimationHelmet>
                        </AnimationHelmet>
                        <AnimationEye left={ '18%' }/>
                        <AnimationEye right={ '18%' }/>
                        <AnimationNose/>
                        <AnimationBlush left={ '10%' }/>
                        <AnimationBlush right={ '10%' } />
                        <AnimationMouth>
                            <AnimationTooth height={ '20%' }/>
                            <AnimationTooth height={ '25%' }/>
                            <AnimationTooth height={ '30%' }/>
                            <AnimationTooth height={ '32%' }/>
                            <AnimationTooth height={ '22%' }/>
                            <AnimationTooth height={ '25%' }/>
                        </AnimationMouth>
                    </AnimationHead>
                </AnimationContainer>  */}
