import styled from "styled-components";
import { Button, Image } from "../../globalStyles";


export const EnlargedImage = styled(Image)`
    width: 80%;
    height: 90%;
    z-index: 99;
    object-fit: contain;
`;

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
    display: ${({ is_open }) => (is_open ? 'flex' : 'none')};
    justify-content: center;
    align-items: center;
    z-index: 98;
`;

export const CloseButton = styled(Button)`
    position: absolute;
    bottom: 95%;
    left: 90%;
`;

export const RightArrow = styled.div`
    width: 50px;
    height: 50px;
    border-bottom: ${({ border }) => ( border ? border :  '20px solid #aaa' )};
    border-left: ${({ border }) => ( border ? border :  '20px solid #aaa' )};
    border-right: transparent;
    border-top: transparent; 
    content: "";
    transform: rotate(225deg);
    cursor: pointer;
`;

export const LeftArrow = styled.div`
    width: 50px;
    height: 50px;
    border-bottom: ${({ border }) => ( border ? border :  '20px solid #aaa' )};
    border-left: ${({ border }) => ( border ? border :  '20px solid #aaa' )};
    border-right: transparent;
    border-top: transparent; 
    content: "";
    transform: rotate(45deg);
    cursor: pointer;
`;