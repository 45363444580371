import styled from "styled-components";
import { Image, Heading, Container } from "../../globalStyles";

export const HeroImage = styled(Image)`
    position: fixed;
    top: 0;
    left: 0;
    /* min-width: 100%; */
    /* height: ${({ height }) => ( height ? height : '100%')}; */
    min-height: 100%;
    z-index: 0;
    object-fit: cover;
    /* box-shadow: grey 0 0 5px 5px; */
`;

export const HeroContainer = styled(Container)`
    position: ${({ position }) => ( position ? position : 'static')}; 
    z-index: ${({ z_index }) => ( z_index ? z_index : '-2')};
    //background: linear-gradient(45deg, #eee, #222); */
    opacity: 1;
/*     width: 100vw; */
`;

export const TempHeroImage = styled.div`
    display: ${({ display }) => ( display ? display : 'inline')};
    background: ${({ background }) => ( background ? background : "")};
    opacity: ${({ opacity }) => ( opacity ? opacity : '1')};
    /* box-shadow: inset 0 0 5rem 1rem #222;  */
    position: ${({ position }) => ( position ? position : 'absolute' )};
    top: 0;
    left: 0;
    width: 100vw;
    height: ${({ height }) => ( height ? height : '50%' )};
    z-index: 0;
`;

export const HeroForegroundBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ height }) => ( height ? height : 'auto')};
    width: 100vw;
    box-shadow: inset 0 0 5rem 1rem #222; 
    z-index:1;
    background: rgba(0, 0, 0, 0.2);
`;

export const HeroHeading = styled(Heading)`
    font-size: ${({ font_size }) => ( font_size ? font_size : 'clamp(2vw, 3rem, 8vw)' )};
    width: 80%;
    color: ${({ color }) => ( color ? color : 'white')};
    text-shadow: ${({ text_shadow }) => ( text_shadow ? text_shadow : 'black 1px 1px')};
    z-index: 1;
    text-align: ${({ text_align }) => ( text_align ? text_align : 'center')};
    word-wrap: break-word;
`;