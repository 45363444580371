import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { NavbarImage } from '../Navbar/NavbarStyles';
import { Image, Column, Grid, } from '../../globalStyles';
import { fetchData } from '../../functions/Functions';
import { FaPhone, FaRegEnvelope, FaLinkedin } from 'react-icons/fa';
import { FooterRow, FooterText } from './FooterStyles';


const Footer = ({ theme }) => {
    const [gridData, setGridData] = useState([]);

    let page = {
        page: 'footer'
    }
    useEffect(() => {
        (async () => {
            const data = await fetchData(page);
            setGridData(data.sections);
        })();
    }, []);

    return (
        <FooterRow
            width={'100vw'}
            height={'100px'}

            background={theme.heroBase}
        >
            <Column>
                <FooterRow padding={'0'}>
                    <a href='#'>
                        <NavbarImage src='/assets/images/logo.png'
                            width={'clamp(100px, 10vw, 200px)'}
                        //margin={'0 1vw 0 0'} 
                        />
                    </a>
                </FooterRow>
            </Column>
            <Grid justify_content='normal'>
                {gridData.map((el, index) => (
                    <FooterRow key={index}
                        max_width='20vw'
                        width='15vw'
                        height='2.5vh'
                        padding={'1rem'}
                        line_height='0.8rem'
                        justify_content='flex-start'
                    >
                        <Image src={el.image} //Image luokka vittuhun tästä ja jokin ketjutettu ternary ikonin valintaa varten. 
                            max_width='30px'
                            width={'30px'}
                            background={'transparent'}
                            max_height='inherit'
                        />
                        <Link to={el.link} >
                            <FooterText color={'#eee'}
                                hoverColor={theme.accent}
                                font_size={'0.5rem'}>
                                {el.text}
                            </FooterText>
                        </Link>
                    </FooterRow>
                ))}
            </Grid>
        </FooterRow>
    )
}

export default Footer
