import { styled } from "styled-components";
import { Column, Heading, Row, Text } from "../../globalStyles";
import { ContentsRow } from "../Contents/ContentsStyles";

export const CardColumn = styled(Column)`
    @media screen and (max-width: 768px){
        padding: 0 10vw;
    }
`

export const CardContainer = styled(Column)`
    width: 65vw;
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
    
`

export const CardRow = styled(Row)`
    @media screen and (max-width: 768px){
        flex-direction: column;
    }
`

export const CardAddressText = styled(Text)`
    color: ${({ color }) => (color ? color : '#aaa')};
    text-align: right;
    @media screen and (max-width: 768px){
        text-align: start;
        padding: 1.5rem 0;
    }
`

export const CardHeading = styled(Heading)`

    color: ${({ color }) => (color ? color : '#aaa')};
    text-align: start;
    font-size: 1.6rem;
    margin-bottom: 0;

    @media screen and (max-width: 768px){
        font-size: 1.6rem;
    }
`
