import React, { useEffect, useState } from 'react';
import { ModalContainer, EnlargedImage, CloseButton, RightArrow, LeftArrow} from './ImageModalStyles';

const ImageModal = ({ is_open, imageUrl, onClose, imageIndex, images, themeAccent }) => {
    const [ newIndex, setNewIndex ] = useState(imageIndex);
    const [ imgSrc, setImgSrc ] = useState( images[imageIndex]);

    useEffect(() => {
        setImgSrc( images[newIndex]);
    }, [ is_open, newIndex ])

    useEffect(() => {
        setNewIndex(imageIndex);
    }, [ onClose ])

    if(is_open){
        console.log( images[newIndex] )
    }

    const handleLeftClick = () => {
        setNewIndex( newIndex>0 ? newIndex-1 : images.length - 1);
        console.log( imgSrc );
    }

    const handleRightClick = () => {
        setNewIndex( newIndex<images.length - 1 ? newIndex+1 : 0);
        console.log( imgSrc );
    }

    return (
        <ModalContainer is_open={ is_open } /* onClick={ onClose } */>
            <LeftArrow onClick={handleLeftClick} color={ themeAccent }/>
            <EnlargedImage src={ imgSrc } alt="Enlarged" />
            <CloseButton onClick={ onClose }> Close </CloseButton>
            <RightArrow onClick={handleRightClick} color={ themeAccent }/>
        </ModalContainer>
    );
};

export default ImageModal