import React, { useState } from 'react'
import { Container, Column, Button, Item, Row, Heading, Text } from '../../globalStyles';
import { Form, FormInput, ContactFormRow } from './ContactFormStyles';
import { formData, formText } from '../../internalData/ContactFormData';
import { ContentsRow } from '../Contents/ContentsStyles';
import axios from 'axios';
import { addHtml } from '../../functions/Functions';

const ContactForm = ({ theme }) => {
    const [formDataVar, setFormDataVar] = useState({
        name: '',
        company: '',
        phone: '',
        email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormDataVar({ ...formDataVar, [e.target.name]: e.target.value });
        console.log(formDataVar)
    }

    const config = {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:8080/api/submit-form', formDataVar, config);
            console.log(response.data);
        } catch (error) {
            console.error('Error: ', error);
        }
    };

    return (
        <ContentsRow height={'auto'}
            z_index={2}
            width={'65vw'}
            margin={'0 0 1.5vh 0'}
            background={theme.neutral}
            box_shadow={'0 0 5px 0px ' + theme.accent}>
            <ContactFormRow justify_content={'flex-start'} padding={'3rem 2%'} align_items='start'>
                <Column >
                    {
                        formText.map((el, index) => (
                            <Item key={index} >
                                <Heading color={theme.text} text_align={'start'}>
                                    {el.title}
                                </Heading>
                                <Text color={theme.text} text_align={'start'}>
                                    {el.text}
                                </Text>
                            </Item>

                        ))
                    }
                    <Text
                        color={theme.text}
                        text_align={'start'}
                    >{addHtml(
                        "</br><strong>Puhelin:</strong> +358 44 999 5855</br><strong>Sähköposti: </strong>antti.lonnqvist@rklprobuilders.fi</strong>"
                    )}</Text>
                </Column>
                {/*<Form onSubmit={handleSubmit}>
                    {
                        formData.map((el, index) => (
                            <Item key={index} padding={'0 1.5rem 0 0'}>
                                <FormInput type={el.type}
                                    id={el.id}
                                    name={el.name}
                                    placeholder={el.placeholder}
                                    value={formDataVar["id"]}
                                    onChange={handleChange}
                                    border={'3px solid ' + theme.accent} />
                                <br />
                            </Item>
                        ))
                    }
                    <Button type='submit'
                        margin='10px 0'
                        box_shadow={'0 0 10px 5px ' + theme.accent}
                        color={theme.neutral}
                        hover_opacity={1}
                        background={theme.accent}
                    >
                        Lähetä
                    </Button>
                </Form>*/}
            </ContactFormRow>
        </ContentsRow>
    )
}

export default ContactForm
