import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import Contents from '../components/Contents/Contents';
import Footer from '../components/Footer/Footer';
import { Container, Column, Row, Heading, Text } from '../globalStyles';
import { useTheme, ThemeButton } from '../Themes';
import ContactForm from '../components/Form/ContactForm'
import { ContentsRow } from '../components/Contents/ContentsStyles';
import { HeroHeading } from '../components/Hero/HeroStyles'


const Yhteystiedot = () => {
    const { toggleTheme, theme } = useTheme();

    return (
        <Container padding={'30vh 0 0 0'}>
            {/*            <ThemeButton onClick={ toggleTheme } >Theme</ThemeButton>*/}
            <Hero theme={theme} frontPage={false} />
            <Column >
                <Navbar theme={theme} />
                <ContentsRow margin={'5rem 0'}
                    width={'65vw'}
                    background='transparent'
                >
                    <Column>
                        <HeroHeading>Yhteystiedot</HeroHeading>
                        <Text color={theme.text}>Ota reippaasti yhteyttä, vastaamme mahdollisimman pian!</Text>
                    </Column>
                </ContentsRow>
                <ContentsRow
                    width={'65vw'}
                    background={theme.neutral}
                    box_shadow={'0 0 5px 0px ' + theme.accent}
                >
                    <Column>
                        <Row justify_content={'space-between'}>
                            <Text color={theme.text} text_align={'start'} width={'auto'}>
                                Antti Lönnqvist <br />
                                Rakennusmestari (AMK) <br />
                                Yrittäjä.
                            </Text>
                            <Text color={theme.text} text_align={'start'} width={'auto'}>
                                Hakkapeliitankatu 14 B 12, 15830 Lahti<br />
                                +358 44 999 5855<br />
                                antti.lonnqvist@rklprobuilders.fi
                            </Text>
                        </Row>
                    </Column>
                </ContentsRow>
                <Contents page={'yhteystiedot'}
                    theme={theme}
                    text_area_width={'65vw'} />
                <ContactForm theme={theme} />
                <Footer theme={theme} />
            </Column>
        </Container>
    )
}

export default Yhteystiedot
