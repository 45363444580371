import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import Contents from '../components/Contents/Contents';
import Footer from '../components/Footer/Footer';
import {
    Column,
    Container
} from '../globalStyles';
import ContactForm from '../components/Form/ContactForm';
import { useTheme, ThemeButton } from '../Themes';

const Home = () => {
    const { toggleTheme, theme } = useTheme();

    return (
        <Container background={theme.heroBase}
            padding={'0'}
        >
            {/*<ThemeButton onClick={ toggleTheme } >Theme</ThemeButton>*/}
            <Hero theme={theme} frontPage={true} />
            <Column >
                <Navbar theme={theme} />
                <Contents page={'home'} theme={theme} />
                <ContactForm theme={theme} />
                <Footer theme={theme} />
            </Column>
        </Container>
    )
}

export default Home
