import styled from "styled-components";
import { Row, Text, Link } from "../../globalStyles";


export const FooterRow = styled(Row)`

    width: ${({ width }) => (width ? width : '15vw')};
    height: ${({ height }) => (height ? height : '2.5vh')};
    justify-content: flex-start;
    padding: 1rem 0;

    @media screen and (max-width: 768px) {
        justify-content: ${({ justify_content }) => (justify_content ? justify_content : 'space-around')} ;
    }
`;

export const FooterText = styled(Text)`
    font-size: 0.8rem;
    line-height: 1.2rem;

    @media screen and (max-width:768px) {
        font-size: 0.8rem;
        line-height: 0.6rem;
        word-wrap: break-word;
    }

    &:hover {
        color: ${({ hoverColor }) => (hoverColor ? hoverColor : '#aaa')};
    }
`;

export const FooterLink = styled(Link)`
    line-height: 0.8rem;

    @media screen and (max-width:1024px) {
        line-height: 0.6rem;
    }
`
