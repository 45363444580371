import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import Contents from '../components/Contents/Contents';
import Footer from '../components/Footer/Footer';
import { Container, Column, Heading, Text } from '../globalStyles';
import { useTheme, ThemeButton } from '../Themes';
import Carousel from '../components/Carousel/Carousel';
import { ContentsRow } from '../components/Contents/ContentsStyles';
import ImageModal from '../components/ImageModal/ImageModal';
import { addHtml, fetchData } from '../functions/Functions';
import CardList from '../components/ReferenceCard/ReferenceCard';
import { HeroHeading } from '../components/Hero/HeroStyles'


const Referenssit = () => {
    const { toggleTheme, theme } = useTheme();
    const [imageClicked, setImageClicked] = useState(false);
    const [fileData, setFileData] = useState("")
    const [imageSrc, setImageSrc] = useState("");
    const [images, setImages] = useState([]);
    const [imageIndex, setImageIndex] = useState(0);
    const [referenceData, setReferenceData] = useState({})
    const [textData, setTextData] = useState([]);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesPerRow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        fade: true,
        arrows: true,
    };

    const carouselProps = {
        position: 'static',
        border_radius: '5vh',
        height: '50vh',
    }

    const handleExitClick = () => {
        setImageClicked(false);
    }

    useEffect(() => {
        fetch('/data/refMainData.json')
            .then((response) => response.json())
            .then((data) => setReferenceData(data.mainText))
            .catch((error) => console.error('Error fetching data: ', error))
    }, [])

    return (
        <Container padding={'30vh 0 0 0'}>
            <Hero theme={theme} frontPage={false} />
            <Column margin={'0 0 0 0'}>
                <Navbar theme={theme} />
                <ContentsRow margin={'5rem 0'}
                    width={'65vw'}
                    background={'transparent'}
                >
                    <Column>
                        <HeroHeading
                            margin_bottom={'1rem'}
                        >
                            Referenssit
                        </HeroHeading>
                        <Text color={theme.text} width={'50vw'}>Alla referenssiluettelo rakennuskohteista vuosilta 2024-2011 sekä kuvagalleria, jossa joitain projekteistamme vuosien varrelta</Text>
                    </Column>
                </ContentsRow>
                <ContentsRow
                    width={'65vw'}
                    box_shadow={'0 0 5px 0px ' + theme.accent}
                    border_radius='20px'
                    margin={'0 0 1.5vh 0'}
                    background={theme.neutral}
                >
                    <Column>
                        <Heading
                            color={theme.text}
                        >
                            {referenceData.title}
                        </Heading>
                        <Text
                            color={theme.text}
                        >
                            {addHtml(referenceData.text)}
                        </Text>
                    </Column>

                </ContentsRow>

                <CardList theme={theme} />

                <ContentsRow background={theme.neutral}
                    width={'65vw'}
                    box_shadow={'0 0 5px 0px ' + theme.accent}
                    border_radius='20px'
                    margin={'0 0 1.5vh 0'}>

                    <Carousel theme={theme}
                        page='referenssit'
                        settings={settings}
                        carouselProps={carouselProps}
                        box_hover={'0 0 5px 2px ' + theme.accent}
                        setImageSrc={setImageSrc}
                        handleImageClick={() => setImageClicked(true)}
                        setImageIndex={setImageIndex}
                        setImagesLarge={setImages}
                    //handleImageClick = { handleImageClick }
                    />

                </ContentsRow>
                <Footer theme={theme} />
                <ImageModal is_open={imageClicked}
                    imageUrl={imageSrc}
                    onClose={handleExitClick}
                    imageIndex={imageIndex}
                    images={images}
                    themeAccent={theme.accent} />
            </Column>
        </Container>
    )
}

export default Referenssit
