import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar/Navbar';
import Hero from '../components/Hero/Hero';
import Contents from '../components/Contents/Contents';
import Footer from '../components/Footer/Footer';
import { Container, Column, Row, Image, Text, Button, Section, Heading } from '../globalStyles';
import { ContentsRow } from '../components/Contents/ContentsStyles';
import { useTheme } from '../Themes';
import { HeroHeading } from '../components/Hero/HeroStyles'

const Palvelut = () => {
    const [fileData, setFileData] = useState([])
    const { toggleTheme, theme } = useTheme();

    return (
        <Container padding={'30vh 0 0 0'} /*background={ theme.heroBase }*/ z_index={'-10'}>
            {/*<ThemeButton onClick={ toggleTheme } >Theme</ThemeButton>*/}
            <Hero theme={theme} frontPage={false} />
            <Column >
                <Navbar theme={theme} />
                <ContentsRow margin={'5rem 0'}
                    width={'65vw'}
                    background={'transparent'}
                    padding={'5vh 2vw'}
                >
                    <Column >
                        <HeroHeading>Palvelut</HeroHeading>
                        <Text color={theme.text}>Tarjoamme seuraavia palveluita: </Text>
                    </Column>
                </ContentsRow>
                <Contents page={'palvelut'}
                    theme={theme} />
                <Footer theme={theme} />
            </Column>
        </Container>
    )
}

export default Palvelut
